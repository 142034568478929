export default async function getUsersListings(user, setUserListingsData) {
  if (user) {
    const targetUrl = `${process.env.CMS_URL}/home-listings?UID=${user.uid}`;

    try {
      const res = await fetch(targetUrl);
      const resData = await res.json();

      // sort by updated at
      const sortedByDateResData = resData.sort((a, b) =>
        a.updated_at < b.updated_at ? 1 : b.updated_at < a.updated_at ? -1 : 0
      );

      setUserListingsData(sortedByDateResData);
    } catch (error) {
      // TODO capture this error
      console.log("error signing up:", error);
    }
  }
}
