import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export default function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: props.theme.palette.success.light,
            },
          }}
          variant="determinate"
          // color="success"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{
            color: `${
              props.customColor
                ? props.customColor
                : props.theme.palette.primary.dark
            } !important`,
          }}
          //   color={props.theme.palette.primary.main}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
