async function getUserRoles(user) {
  const targetUrl = `${process.env.CMS_URL}/user-roles`;

  const token = await user.getIdToken();
  const res = await fetch(targetUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const respJSON = await res.json();

  return respJSON;
}

export default getUserRoles;
