async function getUserPermissions(user) {
  const targetUrl = `${process.env.CMS_URL}/user-permissions`;

  const token = await user.getIdToken();

  const res = await fetch(targetUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const respJSON = await res.json();

  // any filtering on BE going forward

  // if (respJSON) {
  //   // filter out status:deleted loans from users view

  //   const filtered = respJSON.filter((userInsurancePolicy) => {
  //     return (
  //       userInsurancePolicy.Status !== "deleted"
  //       // NOTE: this was a way to differentiate abandoned quote in Loans,
  //       // but insurance quote might ultimately have a different approach
  //       // && Object.keys(userInsurancePolicy.SelectedLoan).length > 0
  //     );
  //   });

  // }
  return respJSON;
}

export default getUserPermissions;
